@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-sans;
  }
}

@layer components {
  .mogok-container {
    max-width: 1114px;
    width: 100%;
    margin: 0 auto;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .faq-accordion h2 {
    display: flex;
    width: 100%;
  }

  .faq-active div span:last-child {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .rotating {
    animation: rotating 10s linear infinite;
  }

  .text-border {
    -webkit-text-stroke: 2px #ffffff;
  }

  .mogok-tabs button {
    position: relative;
  }

  .mogok-tabs button::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 0;
    height: 4px;
    background-color: #D0232D;
    transition: 0.3s ease-in-out;
  }

  .mogok-tabs button:hover::after {
    width: 100%;
  }

  .mogok-tabs button.active-tab::after {
    width: 100%;
  }

  @media screen and (max-width: 640px) {
    .modalAnimation {
      bottom: -150vh;
    }

    .modal-active .modalAnimation {
      bottom: 0;
      animation: modalOpen 0.6s linear forwards;
    }
  }

  @keyframes modalOpen {
    from {
      bottom: -150vh;
    }

    to {
      bottom: 0;
    }
  }
}

@font-face {
  font-display: swap;
  font-family: 'SyneBold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/syne/syne-v15-latin-700.eot');
  src: url('./assets/fonts/syne/syne-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/syne/syne-v15-latin-700.woff2') format('woff2'),
    url('./assets/fonts/syne/syne-v15-latin-700.woff') format('woff'),
    url('./assets/fonts/syne/syne-v15-latin-700.ttf') format('truetype'),
    url('./assets/fonts/syne/syne-v15-latin-700.svg#Syne') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Syne';
  font-style: normal;
  font-weight: 800;
  src: url('./assets/fonts/syne/syne-v15-latin-800.eot');
  src: url('./assets/fonts/syne/syne-v15-latin-800.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/syne/syne-v15-latin-800.woff2') format('woff2'),
    url('./assets/fonts/syne/syne-v15-latin-800.woff') format('woff'),
    url('./assets/fonts/syne/syne-v15-latin-800.ttf') format('truetype'),
    url('./assets/fonts/syne/syne-v15-latin-800.svg#Syne') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/poppins/poppins-v20-latin-regular.eot');
  src: url('./assets/fonts/poppins/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins/poppins-v20-latin-regular.woff2') format('woff2'),
    url('./assets/fonts/poppins/poppins-v20-latin-regular.woff') format('woff'),
    url('./assets/fonts/poppins/poppins-v20-latin-regular.ttf') format('truetype'),
    url('./assets/fonts/poppins/poppins-v20-latin-regular.svg#Poppins') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/poppins/poppins-v20-latin-500.eot');
  src: url('./assets/fonts/poppins/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins/poppins-v20-latin-500.woff2') format('woff2'),
    url('./assets/fonts/poppins/poppins-v20-latin-500.woff') format('woff'),
    url('./assets/fonts/poppins/poppins-v20-latin-500.ttf') format('truetype'),
    url('./assets/fonts/poppins/poppins-v20-latin-500.svg#Poppins') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/poppins/poppins-v20-latin-700.eot');
  src: url('./assets/fonts/poppins/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/poppins/poppins-v20-latin-700.woff2') format('woff2'),
    url('./assets/fonts/poppins/poppins-v20-latin-700.woff') format('woff'),
    url('./assets/fonts/poppins/poppins-v20-latin-700.ttf') format('truetype'),
    url('./assets/fonts/poppins/poppins-v20-latin-700.svg#Poppins') format('svg');
}


@media (max-width:1400px) {
  .nav_bar_ja:lang(ja) {
    gap: 10px;
  }

  .nav_bar_ja_logo:lang(ja) {
    max-width: 160px;
  }
}

@media (min-width:1400px) {
  .nav_bar_ja:lang(ja) {
    gap: 34px;
  }

  .nav_bar_ja_logo:lang(ja) {
    max-width: 194px;
  }
}

@media (max-width:1023px) {
  .nav_bar_ja:lang(ja) {
    gap: 34px;
  }
}

.download_our_whitepaper:lang(ja) {
  width: 400px;
  margin-left: 8px;
}

@media (max-width:768px) {
  .download_our_whitepaper:lang(ja) {
    width: 300px;
  }
}

.browse_and_buy:lang(ja) {
  max-width: 400px;
}

.buy_title1:lang(ja) {
  max-width: 400px;
}

.hideScrollLeft::-webkit-scrollbar {
  display: none;
}